<template>
  <div class="row mb-3">
    <div class="col-12 mt-3 text-center medium bold">
      <h5>Address Details</h5>
    </div>
    <div class="col-12 mb-1">
      <MapAutocomplete
        @inputChanged="updateAddress"
        placeholder="Search Address"
        id="address"
        :value="value"
        :map="true"
        color="outline-green"
      />
    </div>
    <div class="col-12 mb-3 small text-center">
      Enter your house address or post code to select your address
    </div>
    <div class="col-12 mb-3 text-center bold" v-if="value.addressLine1">
      {{ displayAddress }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue'))
  },
  name: 'Wizard Address Details',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters([
      'countries'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    displayAddress () {
      if (this.value.country && this.value.country !== undefined) {
        return this.value.addressLine1 + ', ' + this.value.town + ', ' + this.value.country + ', ' + this.value.postalCode
      } else {
        return this.value.addressLine1 + ', ' + this.value.town + ', ' + this.value.postalCode
      }
    }
  },
  methods: {
    updateAddress (val) {
      const country = this.countries.find(item => item.description === val.country)
      var theTown = ''
      if (val.locality) {
        theTown = val.locality
      } else if (val.postal_town) {
        theTown = val.postal_town
      }
      const address = {
        addressLine1: `${val.street_number} ${val.route}`,
        addressLine2: val.sublocality_level_1,
        town: theTown,
        postalCode: val.postal_code,
        country: (country && country.description) ? country.description : null,
        province: val.province
      }
      this.value = address
    }
  }
}
</script>
